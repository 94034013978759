.MainContainer {
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100vh;
}
a, button { cursor: pointer; }
ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.content-area {
  background-color: #fcfcfc;
  display: flex;
  overflow: hidden;
  overflow-y: hidden;
  .page {
    width: 100%;
    opacity: 1;
    padding: 1rem;
        &.page-with-sidebar-transition-enter .visualize-summary-pivot {
          opacity: 0;
        }
        &.page-with-sidebar-transition-exit .visualize-summary-pivot {
          opacity: 1;
        }
        &.page-with-sidebar-transition-enter-active .visualize-summary-pivot {
          opacity: 1;
        }
        &.page-with-sidebar-transition-exit-active .visualize-summary-pivot {
          opacity: 0;
        }
        .visualize-summary-pivot {
          transition: all 500ms ease-out;
        }
  }

  &.tab-transition-enter,
  &.tab-transition-exit {
    top: 0;
    left: 0;
    transform: scale(1);
  }

  &.tab-transition-enter {
    opacity: 0;
  }

  &.tab-transition-exit {
    opacity: 1;
  }
  &.tab-transition-enter-active {
    opacity: 1;
  }
  &.tab-transition-exit-active {
    transform: scale(0.9);
    opacity: 0;
  }
}

iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
